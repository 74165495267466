<template>
  <div style="display: inline-block; white-space: nowrap;">
    <b-btn
      v-b-tooltip.hover
      variant="none"
      class="btn-none rounded-pill m-0 icone-acao p-0"
      title="Download"
      :disabled="validarRecurso(gridProps.row)"
      @click="baixaEmLoteLinha(gridProps.row)"
    >
      <feather-icon
        icon="DownloadIcon"
        class="text-primary"
        size="20"
      />
    </b-btn>
    <b-btn
      v-b-tooltip.hover
      title="Observação"
      variant="none"
      class="text-primary btn-none"
      style="padding: 0.2rem;"
      @click="openModalObservacao"
    >
      <font-awesome-icon
        icon="fa-regular fa-message"
        :class="{ 'text-warning': temObservacaoNova, 'text-primary': !temObservacaoNova }"
      />
    </b-btn>
    <b-btn
      v-b-tooltip.hover
      title="Enviar por email"
      variant="none"
      class="btn-none icone-acao m-0 p-0"
      :disabled="validarRecurso(gridProps.row)"
      @click="openModalEnviarDocs()"
    >
      <feather-icon
        icon="SendIcon"
        class="text-primary"
        size="20"
      />
    </b-btn>
    <b-btn
      v-b-tooltip.hover
      variant="none"
      title="Histórico de CNDs RFB/PGFN"
      class="btn-none icone-acao m-0 p-0"
      :disabled="(validaCndRfbPgfn(gridProps.row))"
      @click="openModalHistorico(gridProps)"
    >
      <font-awesome-icon
        icon="fa-sharp fa-solid fa-list"
        class="text-primary"
      />
      <modal-historico
        :ref="`modalHistorico${gridProps.row.id}`"
        rotina="CND"
        sub-rotinas="RFB/PGFN"
        des-de="Março de 2024"
        :filtros-rapidos="filtrosRapidos"
        :empresa="gridProps.row"
        :api="apiCnd"
        tipo="rfb"
        :coluna-historico="colunaHistorico"
        :filtro-by-historico="['DataValidade', 'UltimaConsulta']"
      />
    </b-btn>
    <button
      v-b-tooltip.hover="{ boundary: 'window' }"
      title="Enviar por WhatsApp"
      style="font-size: 11.5px; padding: .1rem"
      class="btn btn-sm"
      :disabled="validarRecurso(gridProps.row)"
      @click="openWhatsApp(gridProps.row)"
    >
      <font-awesome-icon
        icon="fa-brands fa-whatsapp"
        class="text-primary"
        style="font-size: 18px"
      />
    </button>

    <envio-email
      ref="modalEnvioEmail"
      :title="`Enviar Documentos ${gridProps.row.razaoSocial}`"
      :sugestao="gridProps.row.email"
      :sugestao-email-adicional="gridProps.row.emailAdicionalEmpresa"
      @enviarEmail="enviarDocumentos"
    />
    <modal-observacao
      :ref="`modalObservacao${gridProps.row.id}`"
      v-model="gridProps.row"
      :api="api"
      :notificar="{ sucesso: notficarSucesso, erro: notificarErro }"
      @change="receberObservacao"
      @hidden="fecharObservacao($event)"
    />
    <loading
      :show-cancel-button="false"
      :is-loading="isLoading"
      :descricao="'Aguarde'"
    />
  </div>
</template>

<style lang="scss">
</style>

<script>
import moment from 'moment'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import colunasCndFederal from '@/app/cnd-federal/shared/components/historico'
import apiCndFederal from '@/app/cnd-federal/shared/services/api'

export default {
  components: {
    EnvioEmail: () => import('@/app/shared/components/envio-email/EnvioEmail.vue'),
    ModalObservacao: () => import('@/app/shared/components/modal-observacao/ModalObservacao.vue'),
    ModalHistorico: () => import('@/app/shared/components/historico/ModalHistorico.vue'),
    Loading: () => import('@pilar/components/loading/Loading.vue'),
  },
  props: {
    gridProps: {
      type: Object,
      required: true,
    },
    api: {
      type: Object,
      required: true,
    },
    filtrosRapidos: {
      type: Array,
      default: () => [],
    },
    filtrosTipoCnd: {
      type: Array,
      default: () => [],
    },
    filtroAtual: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      colunaHistorico: colunasCndFederal,
      apiCnd: apiCndFederal,
      faExternalLinkAlt,
      mostarArquivo: false,
      arquivo: {},
      mostrarPendencias: false,
      temObservacaoNova: false,
      isLoading: false,
    }
  },
  watch: {
    temObservacaoNova(val, oldVal) {
      if (val !== oldVal) {
        this.val = val
      }
    },
  },
  mounted() {
    this.temObservacaoNova = this.gridProps.row.temObservacoesNaoLidas
  },
  methods: {
    openModalEnviarDocs() {
      this.$refs.modalEnvioEmail.abrirModal()
    },
    enviarDocumentos(itens) {
      if (itens.emailsPara.length === 0 && itens.emailsCc.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Informe ao menos um e-mail',
            icon: 'fa-solid fa-circle-exclamation',
            variant: 'danger',
          },
        })
      } else {
        this.$emit('enviar-email', { linha: this.gridProps.row, email: itens })
      }
    },
    baixaEmLoteLinha(linha) {
      this.isLoading = true
      this.api.baixaEmLote({ Docs: this.filtrosTipoCnd, Empresas: [linha.id], Filtro: this.filtroAtual }, '', true)
        .then(payload => {
          this.isLoading = false
          window.open(payload.data)
          this.$sucesso('Download finalizado.')
        })
        .catch(err => {
          this.isLoading = false
          this.$erro(err.message || 'Ocorreu um erro no download.')
          throw err
        })
    },
    openModalObservacao() {
      this.$refs[`modalObservacao${this.gridProps.row.id}`].show()
    },
    fecharObservacao(observacaoNova) {
      this.temObservacaoNova = observacaoNova.hasObservacaoNova
      this.gridProps.row.observacao = ''
    },
    receberObservacao(valor) {
      this.gridProps.row.observacao = valor
    },
    openModalHistorico(props) {
      this.gridProps.row.empresaId = this.gridProps.row.id
      this.$refs[`modalHistorico${props.row.id}`].abrirModal()
    },
    openWhatsApp(row) {
      if (row !== undefined) {
        this.montarMensagemWhatsApp(row)
      } else {
        this.$erro(`Não há nenhuma ${this.rotina} selecionada para envio!`)
      }
    },
    montarMensagemWhatsApp(row) {
      const dias = 15
      this.isLoading = true
      const mensagem = `Olá *${row.razaoSocial}* segue link para download do(s) seguinte(s) documento(s): \r\n\r\n${this.informativoSelecionado(row)}\r\n\r\nApós baixar, solicitamos que faça a conferência dos dados!\r\n\r\n O link ficará disponível até ${moment().add(dias, 'days').format('DD/MM/YYYY')}\r\n`
      this.api.baixaEmLote({ Docs: this.filtrosTipoCnd, Empresas: [row.id], Filtro: this.filtroAtual }, '', true)
        .then(payload => {
          this.isLoading = false
          this.$swal({
            width: 680,
            title: '<div class="text-secondary">Mensagem</div>',
            html: `<div class="text-primary">${(mensagem.replace(/\r\n/g, '<br>'))} <a href="${payload.data}" target="_blank" class="text-secondary" style="text-decoration: underline;">Link do arquivo</a></div>`,
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: 'Copiar link',
            denyButtonText: 'WhatsApp',
            cancelButtonText: 'Cancelar',
            buttonsStyling: false,
            customClass: {
              denyButton: 'btn btn-primary mr-50',
              confirmButton: 'btn btn-outline-primary mr-50',
              cancelButton: 'btn btn-outline-danger mr-50',
            },
            allowOutsideClick: true,
          }).then(result => {
            if (result.isConfirmed) {
              this.$swal('Copiado!', '', 'success')
              navigator.clipboard.writeText(payload.data)
            } else if (result.isDenied) {
              const url = `https://wa.me/?text=${encodeURIComponent(`${mensagem}${payload.data}`)}`
              window.open(url)
            }
          })
        })
        .catch(err => {
          this.isLoading = false
          this.$erro(`Erro ao encaminhar mensagem via WhatsApp: ${err}.`, err)
        })
    },
    informativoSelecionado(row) {
      let mensagemItens = ''
      const tipoCnd = this.tipoCndSelecionado()
      this.loop = tipoCnd.length

      for (let i = 0; i < this.loop; i += 1) {
        mensagemItens += `${tipoCnd[i]} ${this.getStatus(row, tipoCnd[i])}${i === this.loop - 1 ? '' : ','} `
      }
      return mensagemItens
    },
    getStatus(row, tipoCnd) {
      if (tipoCnd === 'CND RFB/PGFN') {
        return this.getDescricao(row.rfbPgfn)
      }
      if (tipoCnd === 'CND FGTS') {
        return this.getDescricao(row.fgts)
      }
      if (tipoCnd === 'CND Trabalhista') {
        return this.getDescricao(row.trabalhista)
      }
      if (tipoCnd === 'CND MTE') {
        return this.getDescricao(row.mte)
      }
      if (tipoCnd === 'CND Estadual') {
        return this.getDescricao(row.estadual)
      }
      if (tipoCnd === 'CND Municipal') {
        return this.getDescricao(row.municipal)
      }
      return '-'
    },
    getDescricao(situacao) {
      if (situacao === 'motivo') {
        return 'Motivo'
      }
      if (situacao === 'regular') {
        return 'Regular'
      }
      if (situacao === 'empregadornaocadastrado') {
        return 'Empregador não cadastrado'
      }
      if (situacao === 'emmanutencao') {
        return 'Em manutenção'
      }
      if (situacao === 'irregular') {
        return 'Irregular'
      }
      return '-'
    },
    validarRecurso(row) {
      if (row.rfbPgfn || row.fgts || row.trabalhista || row.mte || row.estadual || row.municipal) {
        return false
      }
      return true
    },
    validaCndRfbPgfn(row) {
      const recurso = this.validarRecurso(row)
      return !recurso ? !this.tipoCndSelecionado().includes('CND RFB/PGFN') : recurso
    },
    tipoCndSelecionado() {
      this.tipoCnd = this.filtrosTipoCnd
      if (this.filtrosTipoCnd[0] === 'Todos' || !this.filtrosTipoCnd[0]) {
        this.tipoCnd = ['CND RFB/PGFN', 'CND FGTS', 'CND Trabalhista', 'CND MTE', 'CND Estadual', 'CND Municipal']
      }
      return this.tipoCnd
    },
    notificarErro(msg) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: msg,
          icon: 'fa-solid fa-circle-exclamation',
          variant: 'danger',
        },
      })
    },
    notficarSucesso(msg) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: msg,
          icon: 'fa-solid fa-circle-check',
          variant: 'success',
        },
      })
    },
  },
}
</script>
